<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card :loading="isLoading">
      <v-form>
        <!-- Title -->
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <!-- Content -->
        <v-card-text>
          <ErrorsDisplay
            :errors="errors"
            class="ma-4"
          />
          <v-container>
            <v-row>
              <!-- Roles -->
              <v-col cols="12">
                <v-subheader class="pa-0 text-caption">
                  {{ $t('users.dialog.roles-label') }}
                </v-subheader>
                <v-checkbox
                  v-for="(role, index) in roles"
                  :key="index"
                  v-model="selectedRoles"
                  :value="role"
                  :label="role.name"
                  class="checkBox"
                  :hide-details="index !== (roles.length - 1)"
                  :error-messages="validationErrors['roleIds']"
                  :error-count="2"
                />
              </v-col>

              <!-- User groups -->
              <v-col v-if="canSelectUserGroups" cols="12">
                <v-autocomplete
                  :label="$t('users.dialog.usergroups-label')"
                  :search-input.sync="userGroupSearchString"
                  @change="userGroupSearchString = ''"
                  v-model="selectedUserGroupIds"
                  :items="userGroups"
                  item-text="name"
                  item-value="id"
                  chips
                  deletable-chips
                  clearable
                  multiple
                  class="pa-0 ma-0"
                  :error-messages="validationErrors['userGroupIds']"
                  :error-count="1"
                />
              </v-col>

              <!-- Custom user-specific claims -->
              <v-col cols="12">
                <v-autocomplete
                  :label="$t('users.dialog.custom-claims-label')"
                  :search-input.sync="claimSearchString"
                  @change="claimSearchString = ''"
                  v-model="selectedClaims"
                  :items="claims"
                  chips
                  deletable-chips
                  clearable
                  multiple
                  class="pa-0 ma-0"
                  :error-messages="validationErrors['claimIds']"
                  :error-count="1"
                />
              </v-col>

              <!-- Email -->
              <v-col cols="12">
                <v-text-field
                  v-model="emailAddress"
                  :disabled="!isNew"
                  :label="$t('users.dialog.email-address-label')"
                  autocomplete="email"
                  :error-messages="validationErrors['emailAddress']"
                  :error-count="3"
                />
              </v-col>

              <v-col cols="12" v-if="isNew">
              <!-- Require email confirmation -->
                <v-checkbox
                  v-model="sendRegistrationEmail"
                  :disabled="!isAdmin"
                  :label="$t('users.dialog.send-registration-email-label')"
                  :error-messages="validationErrors['sendRegistrationEmail']"
                  :error-count="1"
                />
                <!-- Password field -->
                <v-text-field
                  v-if="!sendRegistrationEmail"
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :label="$t('register.password-label')"
                  autocomplete="new-password"
                  autofocus
                  @click:append="showPassword = !showPassword"
                  :error-messages="validationErrors['password']"
                  :error-count="5"
                />
                <!-- Repeat password field -->
                <v-text-field
                  v-if="!sendRegistrationEmail"
                  v-model="repeatPassword"
                  :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showRepeatPassword ? 'text' : 'password'"
                  :label="$t('register.repeat-password-label')"
                  autocomplete="new-password"
                  @click:append="showRepeatPassword = !showRepeatPassword"
                  :error-messages="validationErrors['repeatPassword']"
                  :error-count="1"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Buttons -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="close"
          >
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="save"
            :loading="isSaving"
            :disabled="isLoading"
          >
            {{ $t('common.buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'
import ErrorsDisplay from '@/components/ErrorsDisplay'

export default {
  components: { ErrorsDisplay },
  props: {
    /**
     * Indicates whether this dialog should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true },
    user: { type: Object, default: null }
  },
  emits: ['itemCreated', 'itemUpdated'],
  data () {
    return {
      userGroupSearchString: '',
      claimSearchString: '',
      selectedUserGroupIds: [],
      selectedClaims: [],
      selectedRoles: [],
      emailAddress: '',
      isSaving: false,
      errors: [],
      validationErrors: {},
      lastSuccessfulLoad: null,
      isLoading: false,
      sendRegistrationEmail: true,
      password: '',
      repeatPassword: '',
      showPassword: false,
      showRepeatPassword: false
    }
  },
  computed: {
    ...mapState('roles', ['roles']),
    ...mapState('claims', ['claims']),
    ...mapState('userGroups', ['userGroups']),
    ...mapGetters('roles', ['getRolesByIds']),
    ...mapGetters('account', ['isAdmin']),
    isNew () {
      return this.user == null
    },
    refetchImminent () {
      return !this.lastSuccessfulLoad || Date.now() - this.lastSuccessfulLoad > 60 * 1000
    },
    formTitle () {
      return this.$t(this.isNew ? 'users.dialog.create-title' : 'users.dialog.edit-title')
    },
    showDialog: {
      get: function () { return this.value },
      set: function (newValue) { this.$emit('input', newValue) }
    },
    canSelectUserGroups () {
      // You can only select user groups for an employee or customer.
      return this.selectedRoles.every((role) => role.name.toLocaleUpperCase() !== 'ADMIN' && role.name.toLocaleUpperCase() !== 'OVERLORD')
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('claims', ['fetchClaims']),
    ...mapActions('userGroups', ['fetchAllUserGroups']),
    ...mapActions('roles', ['fetchRoles']),
    close () {
      this.showDialog = false
    },
    async save () {
      this.isSaving = true
      this.validationErrors = {}
      try {
        // Send a request to create/update the user.
        const response = this.isNew ? await this.createNew() : await this.updateExisiting()
        // Notify the dialog has saved the user.
        if (this.isNew) this.$emit('itemCreated', response.data.createdUser)
        else this.$emit('itemUpdated', response.data)

        if (this.isNew && response.data.sendingRegistrationEmailFailed) {
          // Show warning message when user was created, but email could not be sent.
          this.showSnackbar({
            role: 'warn',
            messages: [this.$t('users.dialog.send-registration-email-fail-during-creation', { emailAddress: response.data.createdUser.emailAddress })],
            duration: 15000
          })
        } else {
          // Show a message indicating the save succeeded.
          this.showSnackbar({
            role: 'success',
            messages: [this.$t('common.save-success-message', { entityName: this.$t('common.user') })],
            duration: 5000
          })
        }

        // Close the dialog.
        this.close()
      } catch (error) {
        console.error(error)
        if (error.response.status === 400 && error.response && error.response.data && error.response.data.errors) {
          if (Array.isArray(error.response.data.errors)) {
            this.errors = error.response.data.errors
          } else {
            this.validationErrors = error.response.data.errors
          }
        } else {
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('common.save-failed-message', { entityName: this.$t('common.user') })],
            duration: 5000
          })
        }
      } finally {
        this.isSaving = false
      }
    },
    async updateExisiting () {
      const payload = {
        id: this.user.id,
        userGroupIds: this.selectedUserGroupIds,
        claims: this.selectedClaims,
        roleIds: this.selectedRoles.map((role) => role.id)
      }
      return await axios.put('users', payload)
    },
    async createNew () {
      const payload = {
        emailAddress: this.emailAddress,
        userGroupIds: this.selectedUserGroupIds,
        claims: this.selectedClaims,
        roleIds: this.selectedRoles.map((role) => role.id),
        sendRegistrationEmail: this.sendRegistrationEmail,
        password: this.sendRegistrationEmail ? null : this.password,
        repeatPassword: this.sendRegistrationEmail ? null : this.repeatPassword
      }
      return await axios.post('users', payload)
    },
    async fetchData () {
      try {
        this.isLoading = true
        await Promise.all([
          this.fetchAllUserGroups(),
          this.fetchClaims(),
          this.fetchRoles()
        ])
        // We need to update the selected roles, because the roles might not've been fetched yet before this method.
        this.selectedRoles = this.user ? this.getRolesByIds(this.user.roleIds) : []
        this.lastSuccessfulLoad = Date.now()
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
        this.close()
      } finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    // Watch for value (v-model) changes. Occurs when the dialog is opened/closed.
    value: {
      immediate: true,
      async handler (newVal, oldVal) {
        // Only update the values if the dialog is being opened.
        if (newVal) {
          this.emailAddress = this.user?.emailAddress ? this.user.emailAddress : ''
          this.selectedRoles = this.user ? this.getRolesByIds(this.user.roleIds) : []
          this.selectedUserGroupIds = this.user ? this.user.userGroupIds : []
          this.selectedClaims = this.user ? this.user.claims : []
          this.sendRegistrationEmail = true
          this.password = ''
          this.repeatPassword = ''
          this.validationErrors = {}

          if (this.refetchImminent) await this.fetchData()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// This will put the checkboxes next to eachother instead of below eachother.
.checkBox {
  display: inline-block;
  margin-right: 15px;
  margin-top: 0px;
}
</style>
