<template>
  <div>
    <UsersTable
      :items="users"
      :total-size="totalSize"
      :page-size="pageSize"
      :page-number="pageNumber"
      :loading="isLoading"
      :title="$t('users.table.title')"
      @pageChanged="pageChanged"
      @itemCreated="onItemCreated"
      @itemUpdated="onItemUpdated"
      @itemDeleted="onItemDeleted"
      @sortingChanged="onSortingChanged"
      @refreshRequested="onRefreshRequested"
      @registrationResent="onRegistrationResent"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions } from 'vuex'
import UsersTable from '@/components/tables/UsersTable'

export default {
  components: { UsersTable },
  data () {
    return {
      users: [],
      isLoading: true,
      totalSize: 0,
      pageSize: 25,
      pageNumber: 1,
      latestSorting: undefined
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('roles', ['fetchRoles']),
    ...mapActions('users', ['fetchUsers', 'fetchUserById']),
    onItemUpdated (updatedUser) {
      // Try to find the updated user among the currently shown users.
      const index = this.users.findIndex((user) => user.id === updatedUser.id)
      if (index !== -1) {
        // Update the user to the new values.
        // $set is a special method to allow vue to react to array changes.
        // See https://vuejs.org/v2/guide/reactivity.html for more info.
        this.$set(this.users, index, updatedUser)
      }
    },
    async onItemCreated (newUser) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onItemDeleted (deletedUser) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onRefreshRequested () {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async pageChanged (newPageNumber) {
      // Update the current page number.
      this.pageNumber = newPageNumber
      // Fetch data with the new page number.
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onSortingChanged (newSorting) {
      this.latestSorting = newSorting
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onRegistrationResent (userId) {
      // Refetch the user's data.
      const freshUserData = await this.fetchUserById(userId)
      // Try to find the user among the currently shown users.
      const index = this.users.findIndex((user) => user.id === userId)
      if (index !== -1) {
        // Update the user to the new values.
        // $set is a special method to allow vue to react to array changes.
        // See https://vuejs.org/v2/guide/reactivity.html for more info.
        // Side note: Updating the user data will also cause the UserTableExpansion to refresh its data, which is what we want.
        this.$set(this.users, index, freshUserData)
      }
    },
    fetchPageDebounced: debounce(async function (pageNumber, pageSize, sorting) {
      await this.fetchPage(pageNumber, pageSize, sorting)
    }, 250),
    async fetchPage (pageNumber, pageSize, sorting) {
      this.isLoading = true
      try {
        // Fetch all users.
        const pagedResponse = await this.fetchUsers({ pageNumber, pageSize, sorting })
        this.users = pagedResponse.items
        this.totalSize = pagedResponse.totalSize
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  created () {
    this.pageNumber = parseInt(this.$route.query.page || this.pageNumber)
  },
  async mounted () {
    await Promise.all([
      this.fetchRoles(),
      this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    ])
  },
  watch: {
    pageNumber (newVal, oldVal) {
      // Do nothing if the route is already correct.
      if (this.$route.query.page && parseInt(this.$route.query.page) === newVal) return
      // Replace the page number in the route/url to be the new page number.
      const query = { ...this.$route.query, page: newVal }
      this.$router.replace({ query })
    }
  }
}
</script>
