<template>
  <span>
    {{ displayText }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    roleIds: { type: Array, required: true }
  },
  computed: {
    ...mapGetters('roles', ['getRolesByIds']),
    roles () {
      return this.getRolesByIds(this.roleIds)
    },
    roleNames () {
      return this.roles.map(r => r.name)
    },
    displayText () {
      return this.roleNames.length > 0 ? this.roleNames.join(', ') : '-'
    }
  }
}
</script>
