<template>
    <v-alert
      border="left"
      :type="alertType"
      elevation="4"
      prominent
      dense
      :icon="alertIcon"
      :color="alertColor"
    >
      <v-row align="center">
        <v-col class="grow">
          <span v-if="registrationIsCompleted">
            {{ $t('users.registration.registration-completed', { date: formattedCompletedDate }) }}
          </span>
          <span v-else-if="registrationIsInvalidated">
            {{ $t('users.registration.registration-invalidated') }}
          </span>
          <span v-else-if="registrationEmailFailed">
            {{ $t('users.registration.registration-email-failed', { emailAddress: userRegistration.emailAddress }) }}
          </span>
          <span v-else-if="registrationIsExpired">
            {{ $t('users.registration.registration-expired', { time: expiryText }) }}
          </span>
          <span v-else>
            {{ $t('users.registration.registration-pending', { time: expiryText }) }}
          </span>
        </v-col>
        <v-col class="shrink" v-if="showResendButton">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="white"
                outlined
                plain
                v-bind="attrs"
                v-on="on"
                :loading="isBusyResending"
                @click="$emit('resendClicked', userRegistration.userId)"
              >
                <v-icon left>
                  mdi-email-fast
                </v-icon>
                {{ $t('users.registration.resend-button') }}
              </v-btn>
            </template>
            <span>{{ $t('users.registration.resend-button-tooltip') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-alert>
</template>

<script>
import moment from 'moment-timezone'

export default {
  props: {
    userRegistration: { type: Object, required: true },
    isBusyResending: { type: Boolean, required: true }
  },
  emits: ['resendClicked'],
  data () {
    return {
      now: moment(),
      nowUpdateTimer: null
    }
  },
  computed: {
    registrationIsExpired () {
      return this.userRegistration && moment(this.userRegistration.expiryDate).isBefore(this.now)
    },
    registrationIsInvalidated () {
      return this.userRegistration && this.userRegistration.isInvalidated
    },
    registrationEmailFailed () {
      return this.userRegistration && this.userRegistration.isFailed
    },
    registrationIsCompleted () {
      return this.userRegistration && this.userRegistration.completedDate
    },
    alertType () {
      if (this.registrationIsCompleted) return 'success'
      else if (this.registrationIsExpired || this.registrationIsInvalidated) return 'warning'
      else if (this.registrationEmailFailed) return 'error'
      else return undefined
    },
    alertColor () {
      if (this.registrationIsCompleted) return undefined
      else if (this.registrationIsExpired || this.registrationIsInvalidated) return undefined
      else if (this.registrationEmailFailed) return undefined
      else return 'info darken-1'
    },
    alertIcon () {
      if (this.registrationIsCompleted) return 'mdi-email-check'
      else if (this.registrationIsExpired || this.registrationIsInvalidated || this.registrationEmailFailed) return 'mdi-email-remove'
      else return 'mdi-email'
    },
    showResendButton () {
      return !this.registrationIsCompleted
    },
    expiryText () {
      if (!this.userRegistration) return null
      else return moment(this.userRegistration.expiryDate).from(this.now)
    },
    formattedCompletedDate () {
      // Moment will automagically apply the current default timezone,
      // which was set with moment.tz.setDefault(timezone).
      return this.userRegistration && this.userRegistration.completedDate
        ? moment(this.userRegistration.completedDate).format('YYYY-MM-DD HH:mm')
        : null
    }
  },
  async mounted () {
    // Start a timer that updates the current time periodically,
    // because vue computeds can't react to time changes.
    this.nowUpdateTimer = setInterval(() => {
      this.now = moment()
    }, 5000)
  },
  beforeDestroy () {
    clearInterval(this.nowUpdateTimer)
  }
}
</script>
