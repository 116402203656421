<template>
  <div>
    <v-skeleton-loader
      v-if="isLoadingUserRegistration"
      type="list-item-avatar"
      class="ma-2"
    />
    <UserRegistrationAlert
      v-else-if="userRegistration"
      :userRegistration="userRegistration"
      :isBusyResending="isBusyResendingRegistrationEmail"
      class="ma-0"
      @resendClicked="onRegistrationResendClicked"
    />

    <SimpleSuspensionsTable
      :title="$t('suspensions.table.title')"
      :items="suspensions"
      :loading="isLoadingSuspensions"
      @itemDeleted="onSuspensionDeleted"
      @itemUpdated="onSuspensionUpdated"
      @sortingChanged="onSortingChanged"
      :features="features"
    />
  </div>
</template>

<script>
import axios from 'axios'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import SimpleSuspensionsTable from '@/components/tables/SimpleSuspensionsTable'
import UserRegistrationAlert from '@/components/alerts/UserRegistrationAlert'

export default {
  components: { SimpleSuspensionsTable, UserRegistrationAlert },
  emits: ['suspensionDeleted', 'suspensionUpdated', 'registrationResent'],
  props: {
    user: { type: Object, required: true }
  },
  data () {
    return {
      suspensions: [],
      userRegistration: null,
      isLoadingSuspensions: true,
      isLoadingUserRegistration: true,
      isBusyResendingRegistrationEmail: false,
      latestSorting: undefined
    }
  },
  computed: {
    ...mapGetters('account', ['isOverlord']),
    features () {
      return this.isOverlord ? ['activate', 'update', 'delete'] : ['activate', 'update']
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('suspensions', ['fetchSuspensionsByUserId']),
    onSuspensionDeleted (item) {
      this.removeFromSuspensions(item.id)
      this.$emit('suspensionDeleted', item)
    },
    onSuspensionUpdated (item) {
      // Try to find the updated suspension among the currently shown suspensions.
      const index = this.suspensions.findIndex((user) => user.id === item.id)
      if (index !== -1) {
        // Update the suspension to the new values.
        // $set is a special method to allow vue to react to array changes.
        // See https://vuejs.org/v2/guide/reactivity.html for more info.
        this.$set(this.suspensions, index, item)
      }
      this.$emit('suspensionUpdated', item)
    },
    async onRegistrationResendClicked (userId) {
      // We want to wait a short while, so the user can see the button spinning even when the request finishes quickly.
      const minWaitTime = new Promise(resolve => setTimeout(resolve, 2000))
      try {
        this.isBusyResendingRegistrationEmail = true
        await axios.post(`users/${userId}/reset-registration`)
        await minWaitTime

        // Show the user that the registration email was resent successfully.
        this.showSnackbar({
          role: 'success',
          messages: [this.$t('users.registration.resend-success')],
          duration: 5000
        })
        // Tell the parent that the registration was reset successfully.
        this.$emit('registrationResent', userId)
      } catch (error) {
        await minWaitTime
        if (error.response.status === 400 && error.response && error.response.data && error.response.data.errors) {
          const messages = Array.isArray(error.response.data.errors)
            ? error.response.data.errors.map(this.getDisplayText)
            : this.validationErrorsToArray(error.response.data.errors)
          this.showSnackbar({
            role: 'error',
            messages: messages,
            duration: 10000
          })
        } else {
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('users.registration.resend-failed-unknown-reason')],
            duration: 10000
          })
        }
        return
      } finally {
        this.isBusyResendingRegistrationEmail = false
      }
    },
    validationErrorsToArray (validationErrorObject) {
      return Object.entries(validationErrorObject).reduce((result, [key, errors]) => {
        return result.concat(errors)
      }, [])
    },
    getDisplayText (error) {
      return typeof error === 'number' ? this.$t(`errors.for-code-${error}`) : error
    },
    removeFromSuspensions (id) {
      // Try to find the item among the currently selected items.
      const index = this.suspensions.findIndex((item) => item.id === id)
      if (index !== -1) {
        // Delete the item from the selected items.
        this.suspensions.splice(index, 1)
      }
    },
    async onSortingChanged (newSorting) {
      this.latestSorting = newSorting
      await this.fetchSuspensionsDebounced(this.user.id, newSorting)
    },
    async fetchMostRecentUserRegistration () {
      try {
        this.isLoadingUserRegistration = true
        const response = await axios.get(`users/${this.user.id}/most-recent-registration`)
        this.userRegistration = response.data
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Do nothing, because old and default accounts don't have registration data.
        } else {
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('errors.loading-data-failed')],
            duration: 5000
          })
        }
      } finally {
        this.isLoadingUserRegistration = false
      }
    },
    async fetchSuspensions (userId, sorting) {
      try {
        this.isLoadingSuspensions = true
        this.suspensions = await this.fetchSuspensionsByUserId({ userId, sorting })
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.isLoadingSuspensions = false
      }
    },
    fetchSuspensionsDebounced: debounce(async function (userId, sorting) {
      await this.fetchSuspensions(userId, sorting)
    }, 250)
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      async handler (newVal, oldVal) {
        // Reset the user registration, because it might've still been filled from another user.
        this.userRegistration = null
        await Promise.all([
          this.fetchSuspensionsDebounced(newVal.id, this.latestSorting),
          this.fetchMostRecentUserRegistration()
        ])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-container {
  display: flex;
  justify-content: center;
}
</style>
